<template>
  <div class="login-ft">
    <a style="font-size:22px" :href="`/pdfjs/web/viewer.html?file=${file}`">使用指南（请务必仔细阅读）</a>
    <p style="font-size:14px;margin-top:28px"></p><!-- 咨询邮箱：info@stoepx.com-->
    <p style="font-size:14px;margin-top:0" v-if="true"><a href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97877707c026159be619e7b83425d6535ac972226fb08c31d3e5c7b48bbfaa8934e3949a352c1fd05bbcee3f0cef96aea7" target="_blank">在线客服</a>（7/24 自动回复）</p>
    <p style="margin-top:4px">Copyright &copy;{{ year }} {{$t('embassy')}}</p>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      year: (new Date()).getFullYear(),
    }
  },
  computed: {
    file() {
      return window.location.origin + '/pdf/user_manual_v1.2.pdf'
    }
  }
}
</script>

<style lang="less" scoped>
.login-ft {
  color: #B8B8B8;
  text-align: center;
  margin-top: 30px;
  p {
    margin: 0;
    margin-top: 8px;
    font-size: 16px;
    span {
      margin: 0 8px;
    }
  }
  a {
    color: #ED6900;
  }
}
</style>
